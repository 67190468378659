


























































































































































.serial-validator {
    input {
        text-transform: uppercase;
    }
}
